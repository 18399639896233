import online from "./helpers/online";
import common from "./helpers/common";
import { handleAxiosError, isUnauthorizedError } from "@/helpers/errors";
import { getIncome } from "./helpers/income";

export default {
  async getEnv({ commit }, url) {
    const env = await common
      .getEnv(url)
      .then((r) => r)
      .catch((e) => e);
    return env;
  },
  async accountState({ commit }, { url, account }) {
    const state = await online.accountState(url, account);
    return state;
  },
  async getShifts({ commit }, data) {
    let time = await online.calculate(data);
    return time;
  },
  async getModelSettlement({ commit }, data) {
    let result = "HELP!!!!!"; //await modelSettlementReport(data)
    commit("setModelSettlment", result);
  },
  async getIncome({ commit }, url) {
    const list = await getIncome(url);
    // console.log(list, 'getIncome')
    commit("setIncome", list);
  },
  async getOnline({ commit }, url) {
    const list = await online.getOnline(url);
    commit("setOnline", list);
  },
  async getLocalStudios({ state, commit }) {
    try {
      const response = await axios.get(`${state.appUrl}getLocalStudios`);
      commit("setLocalStudios", response.data);
    } catch (error) {
      // When we get a 401 on this request, we know that the user,
      // only has access to the current studio
      if (isUnauthorizedError(error)) {
        if (state.selectedStudio) {
          commit("setLocalStudios", [state.selectedStudio]);
        }
        return;
      }

      handleAxiosError(error);
      toastr.error("Failed to get local studios");
    }
  },
  async getVacationSettings({ state, commit }) {
    try {
      const response = await axios.get(`${state.appUrl}getVacationSettings`, {
        params: { studio: state.selectedStudio },
      });

      commit("setVacationSettings", response.data.vacations);
    } catch (error) {
      // When we get a 401 on this request, we know that the user,
      // only has access to the current studio
      if (isUnauthorizedError(error)) {
        return;
      }

      handleAxiosError(error);
      toastr.error("Failed to VacationSettings");
    }
  },
  getEmailCounts({ state, commit }) {
    axios
      .get(state.appUrl + "vue_email_note")
      .then((data) => {
        let emails = {
          report: 0,
          management: 0,
          model: 0,
          app_issue: 0,
          inbox: 0,
          important: 0,
        };

        for (let i of data.data.result) {
          if (i.important) emails.important++;

          if (i.issue) emails.app_issue++;
          else if (i.report) emails.report++;
          else if (i.manager_report) emails.management++;
          else if (i.model_report) emails.model++;
          else emails.inbox++;
        }
        commit("set_email_note", {
          emails,
          total: data.data.total,
        });
        //console.log(emails, data.data.total);
      })
      .catch((error) => {
        handleAxiosError(error);
      });
  },
  async getRangeOfDates({ commit }, data) {
    if (typeof data.start === "number") {
      data.start = moment.unix(data.start);
      data.end = moment.unix(data.end);
    }
    const t = await common.getRangeOfDates(data.start, data.end, data.key);
    return t;
  },
  convertSeconds({ commit }, seconds) {
    let time = common.convertSeconds(seconds);
    return time;
  },
  // Accounts actions ---------------------------------------
  async getAppUsers({ commit, state }, url) {
    url = url || state.appUrl;
    const list = await common.getAppUsers(url);
    if (list && Array.isArray(list)) {
      commit("set_app_users", list);
    }
    return list;
  },
  async getInactiveUsers({ commit, state }) {
    try {
      commit("set_inactive_users_loading", true);
      const response = await axios.post(`${state.appUrl}inactiveUsers`, {
        active: false,
        studios: state.localStudios,
      });
      if (!Array.isArray(response.data)) {
        throw new Error("invalid inactive users");
      }
      commit("set_inactive_users", response.data);
    } catch (error) {
      handleAxiosError(error);
      toastr.error("Failed to get inactive users");
    } finally {
      commit("set_inactive_users_loading", false);
    }
  },
  async updateUserInactive(
    { commit, getters, dispatch, state },
    { user, active }
  ) {
    try {
      // Confirm
      const { value } = await swal.fire({
        text: "Are you sure?",
        type: "question",
        buttonsStyling: false,
        confirmButtonText: active ? "Activate" : "Set inactive",
        confirmButtonClass: active ? "btn btn-primary" : "btn btn-danger",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonClass: "btn btn-label-brand",
      });
      // Return when user dismisses dialog
      if (!value) return;

      // POST request
      const res = await axios.post(state.appUrl + "make_inactive", {
        user,
        active,
      });
      console.log("make_inactive", res);

      // Update state
      const appUsers = getters.filteredUsers;

      if (active) {
        const newUser =
          appUsers.find((v) => v.user === user) ||
          state.appUsers.filter((v) => v.user === user);
        if (newUser) {
          commit("set_app_users", state.appUsers.concat(newUser));
        }
      } else {
        const users = state.appUsers.filter((v) => v.user !== user);
        commit("set_app_users", users);
      }

      dispatch("getInactiveUsers");
      toastr.success(`User ${active ? "activated" : "set as inactive"}`);
      return { success: true };
    } catch (error) {
      handleAxiosError(error);
      toastr.error("Failed to activate user");
    }
  },
  // End accounts actions -----------------------------------
  loadShowShift({ commit }) {
    const value = localStorage.getItem("showConsole");
    commit("setShiftView", typeof value === "string" ? value === "true" : true);
  },
  // Toggle shift
  toggleShift({ commit }, newValue) {
    localStorage.setItem("showConsole", newValue);
    commit("setShiftView", newValue);
  },
  loadShowStatistics({ commit }) {
    const value = localStorage.getItem("showStatistics");
    commit(
      "setStatisticsView",
      typeof value === "string" ? value === "true" : true
    );
  },
  // Toggle statistics
  toggleStatistics({ commit }, newValue) {
    localStorage.setItem("showStatistics", newValue);
    commit("setStatisticsView", newValue);
  },

  // store actions
  changeQuantity({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${data.appUrl}changeQuantity`, {
          id: data.id,
          quantity: parseFloat(data.quantity),
        })
        .then((r) => resolve(r))
        .catch((e) => reject(e));
    });
  },
  addThisToCart({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${data.appUrl}addToCart`, { id: data.item._id })
        .then((r) => resolve(r))
        .catch((e) => reject(e));
    });
  },
  getCheckoutCart({ commit }, appUrl) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${appUrl}getCheckoutCart`)
        .then((r) => resolve(r))
        .catch((e) => reject(e));
    });
  },

  // end store actions
  async getSchedule({ commit, state }, { startOfWeek, endOfWeek, type }) {
    let queries = {};
    let day = startOfWeek;
    let days = [];
    const formated = [];
    let index = 0;
    let sorter = [];

    while (day <= endOfWeek) {
      index++;
      if (queries[day.format("M")] == undefined) {
        queries[day.format("M")] = {
          days: [],
          year: parseFloat(day.format("YYYY")),
        };
      }
      queries[day.format("M")].days.push(parseFloat(day.format("D")));
      let dayFormat = "dddd | MMM Do";
      const label = day.format(dayFormat);
      sorter.push(label);

      let d = day.format("D M YYYY").split(" ");

      formated.push({
        day: d[0],
        month: d[1],
        year: d[2],
        id: day.format("D_M"),
        label,
        index,
      });
      day = day.clone().add(1, "d");
    }
    // let tstc = {
    //   first: [],
    //   second: [],
    // };
    // tstc.first = sorter.slice(0, 15);
    // tstc.second = sorter.slice(15, sorter.length);
    // let arrange = [];
    // for (let i = 0; i <= 15; ++i) {
    //   if (tstc.first[i] != undefined) arrange.push(tstc.first[i]);
    //   if (tstc.second[i] != undefined) arrange.push(tstc.second[i]);
    // }
    days = sorter;
    let q = {
      studio: state.user.studioul,
      queries,
      type: type,
    };
    // console.log(this.$store.state.user, 'this.$store.state.user.studioul',q);

    const r = await axios.post(`${state.appUrl}vue_get_schedule`, q);
    commit("setScheduleResponse", r.data);

    return { r, formated, days };
  },

  async getUserInfo({ state, commit }, user) {
    try {
      const res = await axios.post(state.appUrl + "getUserInfo", { user });

      let data = res.data;
      // if (data.tipCont === "Model") {
      //   data.conturi = {};
      //   let i = setInterval(() => {
      //     const sites = this.sites;
      //     sites.forEach((v) => {
      //       data.conturi[v.toLowerCase()] = {
      //         user: "",
      //         pass: "",
      //         username: "",
      //         aff: "",
      //         activ: null,
      //       };
      //     });
      //     debugger;
      //     commit("set_selected_user", data);
      //     if (sites.length) clearInterval(i);
      //   }, 1000);
      // } else {
      commit("set_selected_user", data);
      // }
    } catch (error) {
      handleAxiosError(error);
    }
  },
  async updateSelectedUser({ commit, state }, user) {
    if (user.tipCont === "Model" && user.conturi?.length > 0) {
      const acc = user.conturi;
      let q = {};
      const OK = Object.keys(acc);

      OK.forEach((v) => {
        if (
          !online.non_adult_accounts.includes(v) &&
          acc[v] &&
          acc[v].username &&
          acc[v].username.length > 2
        )
          q[online.update_ids[v]] = [acc[v].username];
      });
      KTApp.blockPage();
      const states = await online
        .accountState(state.appUrl, q)
        .then((r) => r || [])
        .catch((e) => {
          console.log(e);
          return [];
        });

      KTApp.unblockPage();
      user.states = {};
      if (Array.isArray(states)) {
        states.forEach((v) => {
          user.states[v.site] = v;
        });
      }
    }

    commit("set_selected_user", user);
  },
  async markMessagesSeen({ commit, state }, { from }) {
    const data = {
      to: state.user.user,
      from,
      stamp: moment().unix(),
    };

    const oldCount = state.chatUnseenMessages[from];
    //optimistic update
    commit("setChatUnseenMessages", {
      from,
      count: 0,
    });

    return axios
      .post(state.appUrl + "chat/markMessagesSeen", data)
      .then((res) => {
        commit("setChatUnseenMessages", {
          from,
          count: 0,
        });
      })
      .catch((e) => {
        commit("setChatUnseenMessages", {
          from,
          count: oldCount,
        });
        toastr.error("Faild to load chat messages!");
      });
  },
};
