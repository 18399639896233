const icons = {
  video:
    '<i class="m-nav__link-icon la la-play-circle" style="font-size: 2rem"></i>',
  pdf: '<i class="m-nav__link-icon la la-file-pdf-o" style="font-size: 2rem"></i>',
  txt: '<i class="m-nav__link-icon la la-file-text" style="font-size: 2rem"></i>',
  doc: '<i class="m-nav__link-icon la la-file-word-o" style="font-size: 2rem"></i>',
  docx: '<i class="m-nav__link-icon la la-file-word-o" style="font-size: 2rem"></i>',
  image:
    '<i class="m-nav__link-icon la la-file-image-o" style="font-size: 2rem"></i>',
};

export function isTrainer(user) {
  return user.tipCont === "Trainer" || user.tipCont === "Tehnic";
}

export function isNumber($event) {
  if (/\d/.test($event.key)) {
    return true;
  } else {
    $event.preventDefault();
    return false;
  }
}

export function toLocaleKey(text) {
  return text.replace(/ /gi, "_").toLowerCase();
}

export function signOut(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}logout`)
      .then((r) => resolve(r))
      .catch((e) => {
        reject(e);
      });
  });
}

export function randomID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function checkObjectStringsValues(obj, length) {
  // const valid = await this.checkObjectStringsValues( data, 5 ).then( r => r ).catch( e => { throw e } );
  return new Promise((resolve, reject) => {
    try {
      const keys = Object.keys(obj);
      const filter = keys.filter(
        (v) => typeof obj[v] === "string" && obj[v].length < length
      );
      if (filter.length) {
        filter.forEach((v) =>
          toastr.info(`${v} length must be grater than ${length}`)
        );
        return resolve(false);
      }
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}

export function formatCurrency(sum, fraction, currency) {
  currency = typeof currency !== "undefined" ? currency : "usd";
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: fraction ? 0 : 1, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: fraction ? 0 : 1, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(sum); /* $2,500.00 */
}

export function getImageURL(file, cond, appUrl) {
  return (
    getThumbnailFolder(file, cond, appUrl)
      .replace("vid_thumbs", "vids")
      .replace("thumbs/", "") + file
  );
}

export function getThumbnailURL(file, cond, appUrl) {
  return getThumbnailFolder(file, cond, appUrl) + file.replace(".mp4", ".png");
}

export function getThumbnailFolder(file, cond, appUrl) {
  let url = cond
    ? `${appUrl}training_uploads/thumbs/`
    : file.indexOf(".mp4") > 0
    ? `${appUrl}training/vid_thumbs/`
    : `${appUrl}training/img/thumbs/`;
  return url;
}

export const reportIssueTo = "Report App Issue";
export const shiftReportType = "Shift Report";

export let update_ids = {
  chr: "chr",
  xlv: "xlv",
  epl: "epl",
  dreamcam: "dmc",
  twitter: "twt",
  babestationcams: "bsc",
  mfc: "mfc",
  mfa: "mfa",
  stripchat: "str",
  chaturbate: "ctb",
  bonga: "bng",
  camsoda: "cms",
  cam4: "cm4",
  naked: "nkd",
  camplace: "cmp",
  camgams: "cmg",
  flirt4free: "f4f",
  camdolls: "cmd",
  lj: "ljm",
  smate: "stm",
  cams: "cam",
  adultwork: "adw",
  imlive: "iml",
  xlove: "xlc",
  xmodels: "xmd",
  camcontacts: "cmc",
  chatdf: "cgf",
  eurolive: "elv",
  extasy: "exc",
  xcams: "xcm",
  //exclusive: '',
  ifriends: "ifr",
  youtube: "ytb",
  amazon: "amz",
  // exclusive: "non",
  lovense: "lvs",
  bim: "bim",
  skype: "skp",
};

export let non_adult_accounts = [
  "twitter",
  // "exclusive",
  "youtube",
  "amazon",
];

/** labels for non adult websites + mfa */
export const other_website_labels = {
  amazon: "Amazon",
  skype: "Skype",
  twitter: "Twitter X",
  youtube: "Youtube",
  // adult
  lovense: "Lovense",
  mfa: "MFCAlerts",
};

export let sites_ids = {
  chr: "CherryTv",
  xlv: "XloveCam",
  epl: "Eplay",
  dmc: "Dreamcam",
  mfc: "MyFreeCams",
  mfa: "MFCAlerts",
  str: "Stripchat",
  ctb: "Chaturbate",
  bng: "Bonga",
  cms: "Camsoda",
  cm4: "Cam4",
  nkd: "Naked",
  cmp: "Camplace",
  cmg: "Camgams",
  f4f: "Flirt4Free",
  cmd: "CamDolls",
  ljm: "LiveJasmin",
  bim: "BimBim",
  stm: "Streamate",
  cam: "Cams",
  adw: "AdultWork",
  bsc: "babestationcams",
  iml: "Imlive",
  xlc: "Xlove",
  xmd: "Xmodels",
  cmc: "CamContacts",
  cgf: "ChatGF",
  elv: "EuroLive",
  exc: "Extasy",
  xcm: "XCams",
  ifr: "IFriends",
};

export const active_sites = Object.fromEntries(
  Object.entries(update_ids)
    .map(([id, site]) => {
      // Filter out non-adult websites
      if (other_website_labels[id]) return null;
      return [id, sites_ids[site]];
    })
    .filter((item) => !!item)
);

function calculateOnlineTime(ranges, model, day, finalResult) {
  finalResult = finalResult || {};
  day = day || "1";
  model = model || "self";
  function compare(a, b) {
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
  }
  ranges.sort(compare);
  let state = 0;
  let start = 0;
  let details = [];
  if (finalResult[model] == undefined) finalResult[model] = {};
  if (finalResult[model][day] == undefined) finalResult[model][day] = 0;
  else finalResult[model][day] = 0;
  for (let i in ranges) {
    let event = ranges[i];
    // console.log(event)
    state += event.state;
    if (state > 0) {
      if (start == 0) {
        start = event.time;
      }
    } else {
      if (start != 0) {
        if (event.time > start) {
          let starting = moment.unix(start);
          let ending = moment.unix(event.time);
          finalResult[model][day] += ending.diff(starting, "seconds");
          details.push({
            start: starting.format("Do MMM HH:mm"),
            stop: ending.format("Do MMM HH:mm"),
            time: ending.diff(starting, "seconds"),
          });
        }
        start = 0;
      }
    }
  }
  // console.log(details);
  // console.log(finalResult);
  // console.log(model, day);
  return { details, finalResult };
}

export let incomeType = {
  // Tips              //1
  // Private           //2
  // Spy               //3
  // Group             //4
  // Fanclub           //5
  // Gift              //6
  // Media             //7
  // Fines             //8
  // Referal           //9
  // Other             //10
  // Withdraw          //11
  1: "Tip",
  2: "Private",
  3: "Spy",
  4: "Group",
  5: "Fanclub",
  6: "Gift",
  7: "Media",
  8: "Fines",
  9: "Referal",
  10: "Other",
  11: "Withdraw",
};

export function is_printable(str) {
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 0xff) {
      return false;
    }
  }
  return true;
}

export function sortTimeOnline(onlineTime, model) {
  // console.log("sortTimeOnline", onlineTime, model);
  let testArray = [];
  let resultTime = { self: [] };
  //for(let model in onlineTime){
  for (let data in onlineTime) {
    data = onlineTime[data];
    resultTime.self.push({ time: data.start, state: 1 });
    resultTime.self.push({ time: data.end, state: -1 });
    // console.log(data)
    // for(let i in data) {
    //     let item = data[i];
    //     //console.log(item)
    //     for(let j in item) {
    //         console.log(j, item, item[j])
    //     let r = item[j];
    //     // console.log(r, i)
    //     if(resultTime==undefined) resultTime={}
    //     if(resultTime[i]==undefined) resultTime[i]=[];
    //     testArray.push({time: r.start, state: 1,date:moment(r.start, 'X').format('Do MMM HH:mm'), key:i});
    //     testArray.push({time: r.end, state: -1, date:moment(r.end,'X').format('Do MMM HH:mm'),key:i});
    //     resultTime[i].push({time: r.start, state: 1})
    //     resultTime[i].push({time: r.end, state: -1});
    //     }
    // }
  }
  // console.log(resultTime);
  // console.log(testArray);
  //}
  let ret = [];
  //for(let model in resultTime){
  for (let i in resultTime) {
    // console.log(resultTime[i], i, model)
    ret.push(calculateOnlineTime(resultTime[i], i, model));
  }
  //}
  return ret;
}

export function url_check(inputText, apiUrl, cb) {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a style="text-decoration: none" href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a style="text-decoration: none" href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  // replacePattern3 = /(([a-zA-Z0-9\-_\.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  // replacedText = replacedText.replace(replacePattern3, '<a style="text-decoration: none" href="mailto:$1">$1</a>');

  if (inputText === replacedText) {
    if (cb != undefined) return cb(null, replacedText);
    else if (cb == undefined) return replacedText;
  }

  function getMetaContent(html, name) {
    return html
      .filter((index, tag) => tag && tag.name && tag.name == name)
      .attr("content");
  }
  //cb(null, replacedText);
  let rx = /href="(.*?)"/g;
  let testI = rx.exec(replacedText);
  let url = testI[1];
  //return console.log(replacedText, inputText);
  //let url = 'http://'+inputText.replace(/(http:\/\/|https:\/\/|www\.)/g,'');
  axios
    .post(apiUrl, { url: url })
    .then((data) => {
      //console.log(data.data);
      let html = $(data.data);
      let resp = `
        User Message:<br/> ${inputText}<br />
        <hr />
        Title:  ${html.filter("title").text() || "no title found"}<br/>
        Description: ${
          getMetaContent(html, "description") || "no description found"
        }<br/>
        Image: ${
          (html.find("img").attr("src")
            ? url + "/" + html.find("img").attr("src")
            : "") || "no image found"
        }<br/>
        URL: <a href="${url}" target="_blank">${url}</a>
        `;
      cb(null, resp);
    })
    .catch((e) => {
      cb(null, replacedText);
    });
  // $.ajax({
  //     url: 'https://cors-anywhere.herokuapp.com/'+url
  // }).then( data => {
  //     let html = $(data);
  //     let resp = `
  //     Title:  ${html.filter('title').text() || 'no title found'}<br/>
  //     Description: ${getMetaContent(html, 'description') || 'no description found'}<br/>
  //     Image: ${(html.find('img').attr('src') ? url+'/'+html.find('img').attr('src'):'') || 'no image found'}<br/>
  //     URL: <a href="${url}" target="_blank">${url}</a>
  //     `;
  //     cb(null, resp);
  // }).catch(e=>{
  //     console.log(e);
  //     cb(e.responseText);
  // });
}

export function readURL(elementID) {
  console.log(elementID);
  let that = this;
  function removeFile(e) {
    let id = $(e.target).closest("a").attr("id").replace("target", "");
    let file = $(e.target).closest("a").attr("data-file");
    let uploaded =
      $(`#preview_${file.replace(/\W/g, "")}`).attr("uploaded") || undefined;
    if (uploaded != undefined)
      that.removeImage(
        uploaded,
        $(`#preview_${file.replace(/\W/g, "")}`).attr("path")
      );
    let filter = that.attachedFiles.filter((v) => v.name !== file);
    that.attachedFiles = filter;
    that.attachments.splice(that.attachments.indexOf(file), 1);
    $(`#picture${id}`).remove();
  }
  $(".gallery").not(".add-attachement, .haz-attachement").remove();
  let input = that.attachedFiles;
  for (let i of input) {
    console.log(i.type);
    let reader = new FileReader();
    if (i.type.match("image")) {
      let file = i.name.replace(/\W/g, "");
      let code = htmlPreviewCode(file, i.name, "", icons.image);
      if ($(`.add-attachement`).length)
        $(code).insertBefore(`#${elementID} .add-attachement`);
      else {
        $(`#${elementID}`).append(code);
      }
      if (i.uploaded) {
        $("#preview_" + file).attr(
          "src",
          this.appUrl + i.path + "/thumbs/" + i.name
        );
        $("#preview_" + file).attr("uploaded", i.name);
        $("#preview_" + file).attr("path", i.path);
      } else {
        reader.onload = function (e) {
          let img = e.target.result;
          $("#preview_" + file).attr("src", img);
        };
        reader.readAsDataURL(i);
      }
    } else if (i.type.match("video")) {
      reader.onload = function () {
        let blob = new Blob([reader.result], { type: i.type });
        let url = URL.createObjectURL(blob);
        let video = document.createElement("video");
        let timeupdate = function () {
          if (snapImage(elementID, i.name, video, URLSearchParams)) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage(elementID, i.name, video, url)) {
            video.removeEventListener("timeupdate", timeupdate);
            $(".remove-media")
              .unbind("click")
              .click((e) => {
                removeFile(e);
              });
          }
        });
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = url;
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      reader.readAsArrayBuffer(i);
    } else {
      let ext = i.name.match(/\.[0-9a-z]+$/i)[0];
      let code = htmlPreviewCode(
        i.name.replace(/\W/g, ""),
        i.name,
        null,
        icons[ext.replace(/\./, "")]
      );
      if (`.add-attachement`.length)
        $(code).insertBefore(`#${elementID} .add-attachement`);
      else {
        $(`#${elementID}`).append(code);
      }
      //$(code).insertBefore( `#${elementID} .add-attachement` );
    }
  }
  $(".remove-media")
    .unbind("click")
    .click((e) => {
      removeFile(e);
      // let id = $(e.target).closest('a').attr('id').replace('target','');
      // let file = $(e.target).closest('a').attr('data-file');
      // this.attachments.splice(this.attachments.indexOf(file),1);
      // $(`#picture${id}`).remove();
    });
}

export function removeImage(image, path) {
  // console.log('delete',image);
  axios
    .post(this.appUrl + "removeImage", { image, path })
    .then(() => {
      let rx = new RegExp(
        `\<figure class="image\(| image-style-side| image-style-align-left| image-style-align-center| image-style-align-right\)"\>\<img src="${
          this.appUrl.replace("3001/api", "3000") + path + "/thumbs/" + image
        }"\>\(|\<figcaption\>\(\.\+\?\)\<\/figcaption\>\)\<\/figure\>`,
        "gi"
      );
      let txt = this.editorData.replace(rx, "");
      //console.log(rx);
      this.editorData = txt;
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
}

export function initEditor(editor) {
  // init editor
  let options = {
    modules: {
      toolbar: {},
    },
    placeholder: "Type message...",
    theme: "snow",
  };
  editor = new Quill("#" + editor, options);
}

export const user_key = "da85532cc5fe082d8d6ea3c09c353007";

export function decrypt(data) {
  if (typeof data !== "string") return data;

  let ret = "";

  try {
    data = atob(data);
    for (let i = 0; i < data.length; i++) {
      ret += String.fromCharCode(
        data.charCodeAt(i) ^ user_key.charCodeAt(i % user_key.length)
      );
    }
  } catch (e) {
    console.error("failed to decode data", e);
  }

  return ret;
}

export function addHours(startTime, offset) {
  const [hours, minutes] = startTime.split(":");

  let endHour = parseFloat(hours) + offset;
  endHour = endHour > 23 ? endHour % 24 : endHour;

  return `${zeroPadHour(endHour)}:${minutes}`;
}

export function zeroPadHour(hour) {
  return `${hour <= 9 ? "0" : ""}${hour}`;
}

export function initAttachments(elemId) {
  return false;
  let id = "#" + elemId;
  let previewNode = $(id + " .dropzone-item");
  previewNode.id = "";
  let previewTemplate = previewNode.parent(".dropzone-items").html();
  previewNode.remove();

  let myDropzone = new Dropzone(id, {
    // Make the whole body a dropzone
    url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
    parallelUploads: 20,
    maxFilesize: 1, // Max filesize in MB
    previewTemplate: previewTemplate,
    previewsContainer: id + " .dropzone-items", // Define the container to display the previews
    clickable: id + "_select", // Define the element that should be used as click trigger to select files.
  });

  myDropzone.on("addedfile", function (file) {
    // Hookup the start button
    $(document)
      .find(id + " .dropzone-item")
      .css("display", "");
  });

  // Update the total progress bar
  myDropzone.on("totaluploadprogress", function (progress) {
    document.querySelector(id + " .progress-bar").style.width = progress + "%";
  });

  myDropzone.on("sending", function (file) {
    // Show the total progress bar when upload starts
    document.querySelector(id + " .progress-bar").style.opacity = "1";
  });

  // Hide the total progress bar when nothing's uploading anymore
  myDropzone.on("complete", function (progress) {
    let thisProgressBar = id + " .dz-complete";
    setTimeout(function () {
      $(
        thisProgressBar + " .progress-bar, " + thisProgressBar + " .progress"
      ).css("opacity", "0");
    }, 300);
  });
}

export function initForm(formEl) {
  formEl = KTUtil.getByID(formEl);
  // console.log($(formEl).find('input:not(.ck-hidden)'))
  // Init autocompletes
  // let toEl = KTUtil.find(formEl, '[name=compose_to]');
  let whitelist = this.$store.state.emailUsers.map((v) => {
    return {
      value: v.user,
      email: v.type
        ? `${v.type.replace("Tehnic", "Tech Support")} @ ${v.studio} Studio`
        : "type unknown",
      initials: v.avatar ? "" : v.user.substr(0, 2).toUpperCase(),
      initialsState: v.avatar ? "" : "info",
      pic: v.avatar
        ? `${this.appUrl}modele/${v.user}/img/thumbs/${v.avatar}`
        : "",
      class: "tagify__tag--brand",
      name: "actual name",
    };
  });
  // console.log(whitelist);
  let eles = $(formEl).find(
    "input:not(.ck-hidden, .compose_subject, .multiselect__input)"
  );
  // let txt = $(formEl).find('textarea:not(.ck-hidden .compose_subject)')
  // eles = [...eles,  ...txt];
  for (let ele of eles) {
    // console.log($(ele), ele)
    let tagify = new Tagify(ele, {
      delimiters: ",", // add new tags when a comma or a space character is entered
      maxTags: 10,
      autocomplete: true,
      blacklist: ["fuck", "shit", "pussy"],
      enforceWhitelist: false,
      whitelist,
      // whitelist: [{
      //     value: 'Chris Muller xxx',
      //     email: 'chris.muller@wix.com',
      //     initials: '',
      //     initialsState: '',
      //     pic: './assets/media/users/100_11.jpg',
      //     class: 'tagify__tag--brand'
      // }],
      callbacks: {
        add(v) {
          console.log("add", v.detail.data);
        }, // callback when adding a tag
        remove(v) {
          console.log("remove", v.detail.data);
        }, // callback when removing a tag
      },
      templates: {
        dropdownItem(tagData) {
          try {
            let html = "";

            html += '<div class="tagify__dropdown__item">';
            html += '   <div class="kt-media-card">';
            html +=
              '       <span class="kt-media kt-media--' +
              (tagData.initialsState ? tagData.initialsState : "") +
              '" style="background-image: url(\'' +
              tagData.pic +
              "')\">";
            html +=
              "           <span>" +
              (tagData.initials ? tagData.initials : "") +
              "</span>";
            html += "       </span>";
            html += '       <div class="kt-media-card__info">';
            html +=
              '           <a href="javascript:;" class="kt-media-card__title">' +
              (tagData.value ? tagData.value : "") +
              "</a>";
            html +=
              '           <span class="kt-media-card__desc">' +
              (tagData.email ? tagData.email : "") +
              "</span>";
            html += "       </div>";
            html += "   </div>";
            html += "</div>";

            return html;
          } catch (err) {
            console.log(err);
          }
        },
      },
      transformTag(tagData) {
        tagData.class = "tagify__tag tagify__tag--brand";
      },
      dropdown: {
        classname: "color-blue",
        enabled: 1,
        maxItems: 5,
      },
    });
  }

  // CC input display
  KTUtil.on(
    formEl,
    ".kt-inbox__to .kt-inbox__tool.kt-inbox__tool--cc",
    "click",
    function (e) {
      let inputEl = KTUtil.find(formEl, ".kt-inbox__to");
      KTUtil.addClass(inputEl, "kt-inbox__to--cc");
      KTUtil.find(formEl, "[name=compose_cc]").focus();
    }
  );

  // CC input hide
  KTUtil.on(
    formEl,
    ".kt-inbox__to .kt-inbox__field.kt-inbox__field--cc .kt-inbox__icon--delete",
    "click",
    function (e) {
      let inputEl = KTUtil.find(formEl, ".kt-inbox__to");
      KTUtil.removeClass(inputEl, "kt-inbox__to--cc");
    }
  );

  // BCC input display
  KTUtil.on(
    formEl,
    ".kt-inbox__to .kt-inbox__tool.kt-inbox__tool--bcc",
    "click",
    function (e) {
      let inputEl = KTUtil.find(formEl, ".kt-inbox__to");
      KTUtil.addClass(inputEl, "kt-inbox__to--bcc");
      KTUtil.find(formEl, "[name=compose_bcc]").focus();
    }
  );

  // BCC input hide
  KTUtil.on(
    formEl,
    ".kt-inbox__to .kt-inbox__field.kt-inbox__field--bcc .kt-inbox__icon--delete",
    "click",
    function (e) {
      let inputEl = KTUtil.find(formEl, ".kt-inbox__to");
      KTUtil.removeClass(inputEl, "kt-inbox__to--bcc");
    }
  );
}

function snapImage(elementID, name, video, url) {
  // console.log(elementID, name, video, url);

  let canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
  let image = canvas.toDataURL();
  let success = image.length > 100000;
  if (success) {
    let code = htmlPreviewCode(
      name.replace(/\W/g, ""),
      name,
      image,
      icons.video
    );
    if ($(`.add-attachement`).length) {
      $(code).insertBefore(`#${elementID} .add-attachement`);
    } else {
      $(`#${elementID}`).append(code);
    }
    //$(code).insertBefore( `#${elementID} .add-attachement` );
    URL.revokeObjectURL(url);
  }
  return success;
}

export {
  snapImage,
  // UploadAdapter
};

function htmlPreviewCode(id, name, src, icon) {
  let aicon = icon || "";
  let asrc =
    typeof src === "string"
      ? `<img class="rounded-top" id="preview_${id}" src="${src}">`
      : "";
  let code = `
          <div class="media-item gallery ${
            aicon ? "video-attachement" : ""
          }" style="box-shadow: 2px 2px 2px 1px  rgba(10, 187, 135, 0.5); width: 100px" id="picture${id}">
            <div class="dropdown dropdown-inline" style="right: 3px; top: 3px">
                <button class="p-0 btn btn-secondary btn-icon btn-circle border-0" data-toggle="dropdown"
                  type="button" aria-haspopup="true" aria-expanded="false" style="height: 20px; width: 20px;"
                  >
                      <i class="flaticon-more-1" style="color: rgba(182, 182, 182, 0.8)"></i>
                </button>

                <div class="dropdown-menu dropdown-menu-right" style="z-index: 101;">
                    <a data-file="${name}" id="target${id}" href="javascript:;" class="dropdown-item remove-media">
                        <i class="la la-edit" style="margin-top:-3px;"></i>
                        Delete
                    </a>
                </div>
            </div>
            <div class="media-item-thumb">
                ${asrc}
                ${aicon}
            </div>
            <p class="px-2 mb-1 " style="width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; min-height: 20px;">${name}</p>
          </div>
    `;
  return code;
}

// Example sanitized text
const _exampleText = `
<!-- /* Font Definitions */ @font-face {font-family:"Cambria Math"; panose-1:2 4 5 3 5 4 6 3 2 4; mso-font-charset:0; mso-generic-font-family:roman; mso-font-pitch:variable; mso-font-signature:-536869121 1107305727 33554432 0 415 0;} @font-face {font-family:"Arial Black"; panose-1:2 11 10 4 2 1 2 2 2 4; mso-font-charset:0; mso-generic-font-family:swiss; mso-font-pitch:variable; mso-font-signature:-1610612049 1073772795 0 0 159 0;} /* Style Definitions */ p.MsoNormal, li.MsoNormal, div.MsoNormal {mso-style-unhide:no; mso-style-qformat:yes; mso-style-parent:""; margin:0in; margin-bottom:.0001pt; mso-pagination:widow-orphan; font-size:12.0pt; font-family:"Times New Roman",serif; mso-fareast-font-family:"Times New Roman";} p {mso-style-unhide:no; mso-margin-top-alt:auto; margin-right:0in; mso-margin-bottom-alt:auto; margin-left:0in; mso-pagination:widow-orphan; font-size:12.0pt; font-family:"Times New Roman",serif; mso-fareast-font-family:"Times New Roman";} .MsoChpDefault {mso-style-type:export-only; mso-default-props:yes; font-size:10.0pt; mso-ansi-font-size:10.0pt; mso-bidi-font-size:10.0pt;} @page WordSection1 {size:8.5in 11.0in; margin:1.0in 1.25in 1.0in 1.25in; mso-header-margin:.5in; mso-footer-margin:.5in; mso-paper-source:0;} div.WordSection1 {page:WordSection1;} -->

Text that is sanitized
`;

/** Strips content like:
 *
 */
export const stripComments = (text) => {
  return (
    String(text || "")
      .replace(/&lt;!--(.*)--&gt;/gi, "")
      // Strip preceding empty paragraphs
      .replace(/^<p>\s+<\/p>/, "")
      .replace(/^<p><br\s+\/>\s+<\/p>/, "")
  );
};
